@import url('https://fonts.googleapis.com/css?family=Open+Sans');

body{
  font-family: 'Open Sans', sans-serif ;
}

.airim-widget-image{
  height: auto;
  width: 150%;
  left: -20%;
}


.airim-widget-image-mobile{
  width: 100%;
}

.box-content{
  font-weight: 500;
  color: black;
}

.benefit-icons{
  font-size: 80px;
  color: #8989fc;
}

.feature-card{
  height: 100% !important;
}

.button {
  font-size: 1.2rem !important;
}

.btn-simple{
  border: none !important;
  color:white;
}

.readable-text{
  font-size: 1.2rem !important;
}

.startup-icon-box{
  text-align: left!important;
}

.box-title{
  font-size: 18px !important;
  font-weight: 900 !important;
}

.last-cta{
  border-color: white !important;
  color:white !important;

  &:hover{
    color: $primary !important;
  }
}

.last-cta:hover{
  background-color: white !important;
}

.text-center {
  text-align: center;
}

.get-started{
  padding: 22px 40px !important;
}

.no-padding-top{
  padding-top: 0px;
}

.ggtooltip{
  font-size: 12px !important;
}

.card-feature-description ul{
  font-size: 16px !important;
  text-align: left;
}

.card-feature-description ul li{
  list-style-type: disc;
  padding-bottom: 5px;
}

.brand-logo-integrations {
        padding: 10px 0;
        img {
            max-height: 120px;
        }
    }

.tagline { /* Added by Sujoy */
      color: #585858;
      font-family: 'Nexa Bold', sans-serif;
      margin-bottom: 0;
      z-index: 999;
      font-size: 3.5rem;
      line-height: 1.5 !important;


}

@media only screen and (max-width: 600px) {

  .tagline { /* Added by Sujoy */
      color: #585858;
      font-family: 'Nexa Bold', sans-serif;
      margin-bottom: 0;
      z-index: 999;
      font-size: 3.5rem;
      line-height: 1.2 !important;
  }
}

.subtitle{
  color: #5c5c5c;
  line-height: 1.6;
}

//Featured images
.featured-fh {
  max-height: 450px;
  max-width: 550px;
}

@media (max-height: 767px) {
  .featured-fh {
      max-height: 100% !important;
  }
}

.has-text-right {
  text-align: right !important;
}


.hero-body{
    margin-top: 50px;
}

.airim-screenshot{
  max-width: 400px;
  width: 100%;
}


.navbar-airim{
  color: #555555;

  margin-bottom: 20px;

  .navbar-item{
    padding:0;

    .btn-simple{
      color: #444444;
      &:hover{
        color: $primary !important;
      }
    }

  }
}

.popular-plan{
  border: 2px solid #634ab7;
}

img.partner-logo-sm {
  height: 40px !important;
  // -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  // filter: grayscale(100%) !important; 
  // opacity: 0.4 !important;
}


