/*! _checkboxes.scss | Bulkit | CSS Ninja */

/* ==========================================================================
Checkbox extension 
========================================================================== */

/***
TABLE OF CONTENTS
=============================================================================
0. Charset
1. Colored checkboxes styles
=============================================================================
***/

/* ==========================================================================
0. Charset
========================================================================== */
@charset "UTF-8";

/* ==========================================================================
1. Colored checkboxes styles
========================================================================== */
.b-checkbox {
    position: relative;
    label {
        padding-left: 5px;
        cursor: pointer;
        font-size: 1rem;
        line-height: 1;
    }
    //label
    label::before {
        content: "";
        position: absolute;
        width: 17px;
        height: 17px;
        left: 0;
        top: 1px;
        border: 1px solid #dbdbdb;
        border-radius: 3px;
        background-color: #fff;
        transition: background .1s ease-in-out;
    }
    label::after {
        position: absolute;
        width: 16px;
        height: 16px;
        left: 3px;
        top: 3px;
        font-size: 12px;
        color: #673ab7;
    }
    //hide native input
    input[type="checkbox"],
    input[type="radio"] {
        opacity: 0;
        z-index: 1;
        cursor: pointer;
    }
    //checked state
    input[type="checkbox"]:checked + label::after,
    input[type="radio"]:checked + label::after {
        font-family: "FontAwesome";
        content: "\f00c";
    }
    //disabled state
    input[type="checkbox"]:disabled,
    input[type="radio"]:disabled {
        cursor: not-allowed;
    }
    input[type="checkbox"]:disabled + label,
    input[type="radio"]:disabled + label {
        opacity: 0.65;
    }
    input[type="checkbox"]:disabled + label::before,
    input[type="radio"]:disabled + label::before {
        background-color: whitesmoke;
        cursor: not-allowed;
    }
    //circular style
    &.is-circular label::before {
        border-radius: 50%;
    }
    //inline style
    &.is-inline {
        display: inline-block;
        font-weight: normal;
    }
    &.is-inline + .is-inline {
        margin-left: 10px;
    }
    //colors
    &.is-primary input[type="checkbox"]:checked + label::before {
        background-color: $primary;
        border-color: $primary;
    }

    &.is-primary input[type="checkbox"]:checked + label::after {
        color: $white;
    }
    
    &.is-secondary input[type="checkbox"]:checked + label::before {
        background-color: $secondary;
        border-color: $secondary;
    }

    &.is-secondary input[type="checkbox"]:checked + label::after {
        color: $white;
    }
    
    &.is-accent input[type="checkbox"]:checked + label::before {
        background-color: $accent;
        border-color: $accent;
    }

    &.is-accent input[type="checkbox"]:checked + label::after {
        color: $white;
    }

    &.is-danger input[type="checkbox"]:checked + label::before {
        background-color: $red;
        border-color: $red;
    }

    &.is-danger input[type="checkbox"]:checked + label::after {
        color: $white;
    }

    &.is-info input[type="checkbox"]:checked + label::before {
        background-color: $blue;
        border-color: $blue;
    }

    &.is-info input[type="checkbox"]:checked + label::after {
        color: $white;
    }

    &.is-warning input[type="checkbox"]:checked + label::before {
        background-color: $orange;
        border-color: $orange;
    }

    &.is-warning input[type="checkbox"]:checked + label::after {
        color: $white;
    }

    &.is-success input[type="checkbox"]:checked + label::before {
        background-color: $green;
        border-color: $green;
    }

    &.is-success input[type="checkbox"]:checked + label::after {
        color: $white;
    }
    &.is-dark input[type="checkbox"]:checked + label::before {
        background-color: $coal;
        border-color: $coal;
    }

    &.is-dark input[type="checkbox"]:checked + label::after {
        color: $white;
    }

    input[type="checkbox"].styled:checked + label:after {
        font-family: "FontAwesome";
        content: "\f00c";
    }

    input[type="checkbox"] .styled:checked + label::before {
        color: #fff;
    }

    input[type="checkbox"] .styled:checked + label::after {
        color: #fff;
    }
}

